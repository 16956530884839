import "antd/dist/antd.css"; // or 'antd/dist/antd.less'
import React, { useEffect, Suspense, lazy } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route, Switch, useHistory, withRouter } from "react-router-dom";
import { clearMessage } from "../actions/message";
import AboutUs from "./components/AboutUs";
import AdminsTable from "./components/AdminsTable";
import AdminUserPassword from "./components/AdminUserPassword";
import Dashboard from "./components/Dashboard";
import Eula from "./components/Eula";
import ForgetPassword from "./components/ForgetPassword";
import { useIdleTimer } from "react-idle-timer";
import Header from "./components/Header";
import InactiveUsers from "./components/InactiveUsers";
import homeindex from "./components/index.js";
const Issues = lazy(() => import("./components/Issues"));
const Login = lazy(() => import("./components/Login"));
const Map = lazy(() => import("./components/Map"));
const Organization = lazy(() => import("./components/Organization"));
const OrganizationProjects = lazy(() =>
  import("./components/OrganizationProjects")
);
const PolicyUpdate = lazy(() => import("./components/PolicyUpdate"));
const PrivacyPolicy = lazy(() => import("./components/PrivacyPolicy"));
const ResetPassword = lazy(() => import("./components/ResetPassword"));
const ResetPasswordRedirect = lazy(() =>
  import("./components/ResetPasswordRedirect")
);
const Signin = lazy(() => import("./components/SignUp"));
const SiteMap = lazy(() => import("./components/SiteMap"));
const UserList = lazy(() => import("./components/UserList"));
const Termsuse = lazy(() => import("./components/Termsuse"));
const Tips = lazy(() => import("./components/Tips"));
const UpdatePassword = lazy(() => import("./components/UpdatePassword"));
const UpdateUser = lazy(() => import("./components/UpdateUser"));
const UserLogs = lazy(() => import("./components/UserLogs"));
const ErrorLogs = lazy(() => import("./components/ErrorLogs.js"));
const FileLogs = lazy(() => import("./components/FileLogs"));
const AdminUserProfile = lazy(() => import("./components/AdminUser"));
const AccountVerifyMessage = lazy(() =>
  import("./components/AccountVerifyMessage")
);
const AddUserInOrganization = lazy(() =>
  import("./components/AddUserInOrganization")
);
import secureLocalStorage from "react-secure-storage";
import "./styles/App.css";
import "./styles/CopyRight.css";
import "./styles/Footer.css";
import "./styles/header.css";
import "./styles/MainBg.css";
import "./styles/Map.css";
import "./styles/mapview.css";
import "./styles/Responsive.css";
import "./styles/signin.css";
import "./styles/Video.css";
import { API_URL, Alert, displayMessage } from "./common/common.js";
import { login, logout } from "../actions/auth";
const EulaHistory = lazy(() => import("./components/Eulaistory"));
const OrganizationTable = lazy(() => import("./components/OrganizationTable"));
const UpgradePlans = lazy(() => import("./components/UpgradePlans"));
const ManagePlans = lazy(() => import("./components/ManagePlans"));
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { validateShareToken } from "../services/user.service";
import ShareLoading from "./components/ShareLoading";
import { authHeader } from "../services/auth-header";
import axios from "axios";
import Subscriptions from "./components/Subscriptions.js";

const App = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { isLoggedIn, isAdmin, isOrganizationAdmin, isOtp } = useSelector(
    (state) => state.auth
  );
  if (
    window.location.pathname.includes("set-password") &&
    secureLocalStorage.getItem("token")
  ) {
    dispatch(logout());
  }
  if (window.location.href.includes("share?token=")) {
    let sessionCheck = secureLocalStorage.getItem("token");
    validateShareToken(window.location.href.split("token=")[1])
      .then((res) => {
        secureLocalStorage.clear();
        history.push("/share");
        if (res.data.zoomTo) {
          secureLocalStorage.setItem("shareZoom", res.data.zoomTo);
        }
        if (sessionCheck) {
          secureLocalStorage.setItem("reloadMap", 1);
        }
        if (res.data.organizationData) {
          if (res.data.organizationData?.logoUrl) {
            secureLocalStorage.setItem(
              "organizationLogo",
              res.data.organizationData?.logoUrl
            );
          }
          if (res.data.organizationData?.style) {
            if (
              typeof JSON.parse(res.data.organizationData?.style) ===
                "object" &&
              JSON.parse(res.data.organizationData?.style)
            ) {
              secureLocalStorage.setItem(
                "organizationColor",
                JSON.parse(res.data.organizationData?.style).header
              );
              secureLocalStorage.setItem(
                "organizationText",
                JSON.parse(res.data.organizationData?.style).text
              );
            }
          }
        }
        dispatch(login(res.data.shareUser, res.data.sharePass));
      })
      .catch((err) => {
        if (sessionCheck) {
          history.push("/");
          window.location.reload();
        } else {
          history.push("/login");
        }
        dispatch(displayMessage("error", "Invalid Link!"));
      });
  }
  useEffect(() => {
    history.listen((location) => {
      dispatch(clearMessage()); // clear message when changing location
    });
  }, [dispatch]);
  useEffect(() => {
    isLoggedIn &&
      window.location.pathname !== "/upgrade-plan" &&
      window.location.pathname !== "/manage-plan" &&
      window.location.pathname !== "/user-profile" &&
      window.location.pathname !== "/terms-of-service" &&
      history.push("/");
  }, [isLoggedIn]);
  useEffect(() => {
    const ele = document.getElementById("ipl-progress-indicator");
    if (ele) {
      ele.outerHTML = "";
    }
    if (authHeader() && Number(secureLocalStorage.getItem("isAdmin")) !== 1)
      axios
        .get(API_URL + "users/aws-credentials", {
          headers: { Authorization: `Bearer ${authHeader()}` },
        })
        .then((response1) => {
          secureLocalStorage.setItem(
            "SessionToken",
            response1.data.SessionToken
          );
          secureLocalStorage.setItem("ACCESS_ID", response1.data.AccessKeyId);
          secureLocalStorage.setItem(
            "SECRET_KEY",
            response1.data.SecretAccessKey
          );
        });
  }, []);
  const handleOnIdle = (event) => {
    if (isLoggedIn && !secureLocalStorage.getItem("uploading")) {
      history.push("/login");
      dispatch(logout());
      history.push("/login");
      dispatch(displayMessage("error", "Session expired due to no activity"));
    }
  };

  const handleOnActive = (event) => {
    // console.log('user is active', event)
    // console.log('time remaining', getRemainingTime())
  };

  const handleOnAction = (event) => {
    // console.log('user did something', event)
  };

  const { getRemainingTime, getLastActiveTime } = useIdleTimer({
    timeout: 1000 * 60 * 30,
    onIdle: handleOnIdle,
    onActive: handleOnActive,
    onAction: handleOnAction,
    debounce: 500,
  });
  window.location.pathname == "/" && !isLoggedIn && history.push("/login");
  // if (
  //   (window.location.host.includes("local") ||
  //     window.location.host.includes("dev")) &&
  //   secureLocalStorage.getItem("encrypt") !== "metageo2024!"
  // ) {
  //   history.push("/");
  //   var password = prompt("Enter in the password");
  //   if (password == "metageo2024!") {
  //     secureLocalStorage.setItem("encrypt", password);
  //     window.location.reload();
  //   } else {
  //     window.location.reload();
  //   }
  //   return <div></div>;
  // }
  return (
    <div>
      {window.location.href.includes("share?token=") ? null : <Header />}
      <Suspense
        fallback={
          <Backdrop
            open={true}
            style={{
              zIndex: 999999999999999999,
              color: "#fff",
            }}
          >
            <CircularProgress />
          </Backdrop>
        }
      >
        <Switch>
          {isLoggedIn && !isAdmin ? (
            <>
              <Route
                exact
                path="/"
                component={withRouter(
                  window.location.href.includes("share?token=")
                    ? ShareLoading
                    : Map
                )}
              />
              <Route
                path="/update-password"
                component={withRouter(UpdatePassword)}
              />
              <Route path="/update-user" component={withRouter(UpdateUser)} />
              <Route
                path="/upgrade-plan"
                component={withRouter(UpgradePlans)}
              />
              <Route path="/manage-plan" component={withRouter(ManagePlans)} />
              <Route
                path="/organization"
                component={withRouter(Organization)}
              />
              <Route
                path="/organization-projects"
                component={withRouter(OrganizationProjects)}
              />
              <Route
                path="/terms-of-service"
                component={withRouter(PrivacyPolicy)}
              />
            </>
          ) : (
            <Route
              exact
              path="/"
              component={withRouter(isAdmin ? Dashboard : Login)}
            />
          )}
          <Route path="/login" component={withRouter(Login)} />
          <Route path="/reset-password" component={withRouter(ResetPassword)} />
          <Route
            path="/reset-key/:key"
            component={withRouter(ResetPasswordRedirect)}
          />
          <Route
            path="/set-password/:key"
            component={withRouter(AdminUserPassword)}
          />
          <Route
            path="/forget-password"
            component={withRouter(ForgetPassword)}
          />
          {!window.location.host.includes("dev") && (
            <Route path="/signup" component={withRouter(Signin)} />
          )}
          <Route path="/share" component={withRouter(ShareLoading)} />
          <Route
            path="/verify-account/:uid"
            component={withRouter(AccountVerifyMessage)}
          />
          <Route
            path="/add-user/:orgId/:uid"
            component={withRouter(AddUserInOrganization)}
          />
          {isAdmin && (
            <>
              <Route path="/Users" component={withRouter(UserList)} />
              <Route path="/Tips" component={withRouter(Tips)} />
              <Route
                path="/InactiveUsers"
                component={withRouter(InactiveUsers)}
              />
              <Route
                path="/Subscriptions"
                component={withRouter(Subscriptions)}
              />
              <Route
                path="/all-organizations"
                component={withRouter(OrganizationTable)}
              />
              <Route path="/Admins" component={withRouter(AdminsTable)} />
              <Route path="/Issues" component={withRouter(Issues)} />
              <Route path="/eula-history" component={withRouter(EulaHistory)} />
              <Route path="/userLogs" component={withRouter(UserLogs)} />
              <Route path="/errorLogs" component={withRouter(ErrorLogs)} />
              <Route
                path="/user-profile"
                component={withRouter(AdminUserProfile)}
              />
              <Route path="/fileLogs" component={withRouter(FileLogs)} />
              <Route path="/Dashboard" component={withRouter(Dashboard)} />
              <Route
                path="/PolicyUpdate"
                component={withRouter(PolicyUpdate)}
              />
              <Route
                path="/update-password"
                component={withRouter(UpdatePassword)}
              />
            </>
          )}
          <Route path="/AboutUs" component={withRouter(AboutUs)} />
          <Route path="/health">
            <h3>Hey There!!! The App is Healthy</h3>
          </Route>
          <Route path="/SiteMap" component={withRouter(SiteMap)} />
          <Route
            path="/terms-of-service"
            component={withRouter(PrivacyPolicy)}
          />
          <Route path="/Termsuse" component={withRouter(Termsuse)} />
          <Route path="/Eula" component={withRouter(Eula)} />
          <Route path="*" exact={true} component={withRouter(Login)} />
        </Switch>
      </Suspense>
      <Alert />
    </div>
  );
};

export default App;
