import React, { useState, createContext } from "react";
import { shallowEqual } from "react-redux";
const DataContext = createContext();
const DataProvider = (props) => {
  const [appState, setAppState] = useState(
    {
      reloadProjects: true,
      isLoading: false,
      fileId: null,
      folderId: null,
      projectId: null,
      selectedItem: null,
      progress: null,
      projects: [],
      layerArray: [],
    },
    shallowEqual
  );
  return (
    <DataContext.Provider value={{ appState, setAppState }}>
      {props.children}
    </DataContext.Provider>
  );
};

export { DataContext };
export default DataProvider;
